import React from "react";
import GtiLoading from "../../components/GtiLoading";
import GtiStateMessage from "../../components/GtiStateMessage";
import { AuthContext } from "../../App";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Row, Alert, Col, message } from "antd";
import config from "../../config";
import GtiTable from "../../components/GtiTable";
import GtiSearch from "../../components/GtiSearch";

/** Arreglo donde se almacenan los valores a enviar */
var valuesToPost = [];

/** Estado inicial */
const initialState = {
  pendingDevices: [],
  groupId: 0,
  fileUploaded: null,
  showResults: false,
  hasDevices: false,
  devicesToAuthorize: null,
  isFetching: false,
  isAuthorizing: false,
  isSuccess: false,
  hasError: false,
};

/** Reducer que define que hacer con cada dispatch */
const reducer = (state, action) => {
  switch (action.type) {
    case "OBTAIN_DEVICES_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "OBTAIN_DEVICES_SUCCESS":
      return {
        ...state,
        isFetching: false,
        pendingDevices: action.payload,
      };
    case "OBTAIN_DEVICES_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    case "OBTAIN_DEVICE_GROUP_ID":
      return {
        ...state,
        isFetching: false,
        groupId: action.payload,
      };
    case "FILE_UPLOADED":
      return {
        ...state,
        fileUploaded: action.value,
      };
    case "SHOW_RESULTS":
      return {
        ...state,
        showResults: true,
      };
    case "ENABLE_AUTHORIZE_BUTTON":
      return {
        ...state,
        hasDevices: true,
      };
    case "AUTHORIZING_DEVICES":
      return {
        ...state,
        isAuthorizing: true,
      };
    case "AUTHORIZING_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        successMessage: action.payload,
      };
    default:
      return state;
  }
};

/** Constante del componente PendingDevices */
const PendingDevices = ({ groupId, onSuccessFunction, onReloadFunction }) => {
  const { state: authState } = React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [value, setValue] = React.useState("");
  const [dataSource, setDataSource] = React.useState(state.pendingDevices);

  /** Columnas de la tabla de dispositivos */
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.id - b.id,
    width: 120
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
  },
  {
    title: "Tipo",
    dataIndex: "modelo",
    key: "modelo",
  },
];

  /** Se obtienen las terminales pendientes de autorizacion en Revolution */
  React.useEffect(() => {
    dispatch({
      type: "OBTAIN_DEVICES_REQUEST",
    });
    fetch(config.api.url + "terminales/pendientes", {
      headers: {
        Authorization: `bearer ${authState.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        let pendingDevices = resJson.data;
        setDataSource(pendingDevices);
        dispatch({
          type: "OBTAIN_DEVICES_SUCCESS",
          payload: pendingDevices,
        });
      })
      .catch((error) => {
        dispatch({
          type: "OBTAIN_DEVICES_FAILURE",
        });
      });
  }, [authState.token]);

  /** Constante de seleccion */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      valuesToPost = [];
      if (selectedRows != null) {
        for (var i in selectedRows) {
          valuesToPost.push({
            Id: selectedRows[i].id,
            Nombre: selectedRows[i].nombre,
            grupoPadreId: groupId,
            DeviceTypeId: selectedRows[i].deviceTypeId
          });
        }
      } else {
        valuesToPost = [];
      }
    },
  };

  /** Función que le permite al usuario filtrar el elemento en tiempo real */
  function generalSearch(e) {
    const currentValue = e.target.value;
    setValue(currentValue);
    const currentDevices = state.pendingDevices;
    const formattedValue = currentValue.toLowerCase();
    const filteredDevices = currentDevices.filter((device) => {
      return (
        device.id.toString().includes(formattedValue) ||
        device.nombre.toLowerCase().includes(currentValue) ||
        device.devicetypeid.toLowerCase().includes(currentValue)
      );
    });
    setDataSource(filteredDevices);
  }

   /** Funcion que permite la busqueda desde cualquier columna */
   function searchElement(selectedKeys, confirm) {
    confirm();
  }

  /** Borra los filtros en cualquier columna */
  function handleReset(clearFilters) {
    clearFilters();
  }

  /** Codigo en HTML de lo que se le muestra al usuario */
  return (
    <div className="App">
      {state.isFetching ? (
        <GtiLoading
          loadingTitle={"Cargando dispositivos pendientes"}
          paddingTop={50}
          paddingBottom={50}
        />
      ) : state.hasError ? (
        <GtiStateMessage
          isSuccess={false}
          iconColor={"red"}
          iconSize={40}
          messageText={
            "Ha ocurrido un error al consultar/autorizar los dispositivos"
          }
          buttonText={"Aceptar"}
        />
      ) : (
            <Form onSubmit={handleSubmit}>
              <Row style={{ paddingBottom: 20 }}>
                <Col span={24}>
                  <Alert
                    description={
                      <div>
                        <Row>
                          Solo las terminales conectadas se mostrarán en la
                          lista.
                        </Row>
                      </div>
                    }
                    type="warning"
                    showIcon
                  />
                </Col>
              </Row>
              <Row style={{ textAlign: "right", paddingBottom: 20 }}>
                <Col span={24}>
                  <GtiSearch
                    placeholder={"Buscar..."}
                    value={value}
                    width={300}
                    onSearchFunction={(e) => generalSearch(e)}
                  />
                </Col>
              </Row>
              <Row style={{ paddingBottom: 20 }}>
                <Col span={24}>
                  <Form.Item>
                    <GtiTable
                      rowKey={"id"}
                      rowSelection={{ ...rowSelection }}
                      dataSource={dataSource}
                      paginationElement={"terminales"}
                      columns={columns}
                      emptyText={"No hay terminales por autorizar"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ textAlign: "right", paddingBottom: 20 }}>
                <Col span={24}>
                  <Button
                    disabled={dataSource.length < 1}
                    loading={state.isAuthorizing}
                    type="primary submit"
                    htmlType="submit"
                    className="primary"
                  >
                    {state.isAuthorizing ? "Autorizando..." : "Autorizar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
    </div>
  );

  function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    dispatch({
      type: "AUTHORIZING_DEVICES",
    });
    var bodyToPost = JSON.stringify({ terminales: valuesToPost });
    fetch(config.api.url + "Terminales/Autorizar", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${authState.token}`,
      },
      body: bodyToPost,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          dispatch({
            type: "OBTAIN_DEVICE_FAILURE",
          });
        }
      })
      .then((resJson) => {
        message.success(resJson.mensaje);
        onSuccessFunction();
        onReloadFunction();
      });
  }
};

export default PendingDevices;
